import ApplicationController from "./application_controller"
import { FetchRequest } from "@rails/request.js"

export default class extends ApplicationController {
  static values = {
    url: String,
    method: String
  }

  async ajax(event) {
    const { target } = event
    const request = new FetchRequest(this.methodValue, this.urlValue)
    const response = await request.perform();
    if (response.ok) {
      target.classList.add("form-border--success")
      target.setAttribute("aria-checked", "true")

      setTimeout(function(element) {
        element.classList.remove("form-border--success")
      }, 3000, target)
    } else {
      target.classList.add("form-border--error")
      target.setAttribute("aria-checked", "false")

      setTimeout(function(element) {
        element.classList.remove("form-border--error")
      }, 3000, target)
    }
  }
}
