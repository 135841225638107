import { Application } from "@hotwired/stimulus"

import ReplaceController from "./replace_controller"
import LinkController from "./link_controller"
import TimeoutController from "./timeout_controller"
import ChartController from "./chart_controller"
import CalendarController from "./calendar_controller"
import CalendarFilterController from "./calendar_filter_controller"
import ModalController from "./modal_controller"

window.Stimulus = Application.start()
Stimulus.register("replace", ReplaceController)
Stimulus.register("link", LinkController)
Stimulus.register("timeout", TimeoutController)
Stimulus.register("chart", ChartController)
Stimulus.register("calendar", CalendarController)
Stimulus.register("calendar-filter", CalendarFilterController)
Stimulus.register("modal", ModalController)
