import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    options: { type: Object, default: {} }
  }

  connect() {
    this.chart = new ApexCharts(this.element, this.optionsValue);
    this.chart.render();
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
