import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["button", "content"]

  toggle() {
    const expanded = this.buttonTarget.getAttribute("aria-expanded")

    if (expanded === "false") {
      this.buttonTarget.setAttribute("aria-expanded", "true")
      this.contentTarget.setAttribute("data-expanded", "true")
    } else {
      this.buttonTarget.setAttribute("aria-expanded", "false")
      this.contentTarget.setAttribute("data-expanded", "false")
    }
  }
}
