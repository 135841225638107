// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import "./controllers"

ActiveStorage.start();

document.addEventListener("turbo:load", function() {
  const toggle = document.querySelector("button.nav-toggle")
  const nav = document.querySelector("#hamburger-nav-container")

  if (!toggle) { return }
  toggle.addEventListener("click", () => {
    const expanded = toggle.getAttribute("aria-expanded")
    if (expanded === "false") {
      toggle.setAttribute("aria-expanded", "true")
      nav.setAttribute("data-expanded", "true")
    } else {
      toggle.setAttribute("aria-expanded", "false")
      nav.setAttribute("data-expanded", "false")
    }
  });
});
