import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["month", "year", "days", "weekdayHeaders"]
  static classes = ["selected"]

  initialize() {
    this.date = new Date()
    this.render()
  }

  previousMonth() {
    const currentYear = this.date.getFullYear()
    const yearSelect = this.yearTarget.querySelector("select")
    const minYear = parseInt(yearSelect.options[0].value)

    if (currentYear > minYear || this.date.getMonth() > 0) {
      this.date.setMonth(this.date.getMonth() - 1)

      if (this.date.getMonth() === 11) {
        this.date.setFullYear(currentYear - 1)
      }

      this.render()
    }
  }

  nextMonth() {
    const currentYear = this.date.getFullYear()
    const yearSelect = this.yearTarget.querySelector("select")
    const maxYear = parseInt(yearSelect.options[yearSelect.options.length - 1].value)

    if (currentYear < maxYear || this.date.getMonth() < 11) {
      this.date.setMonth(this.date.getMonth() + 1)

      if (this.date.getMonth() === 0) {
        this.date.setFullYear(currentYear + 1)
      }

      this.render()
    }
  }

  render() {
    const today = new Date()

    this.monthTarget.textContent = new Intl.DateTimeFormat("en-ZA", { month: "short", }).format(this.date).substring(0, 3)
    this.monthTarget.dataset.monthIndex = this.date.getMonth()
    this.yearTarget.textContent = ""
    this.daysTarget.innerHTML = ""
    this.weekdayHeadersTarget.innerHTML = ""

    // Create the year select dropdown.
    const yearSelect = document.createElement("select")
    yearSelect.classList.add("year-select")
    yearSelect.dataset.calendarFilterTarget = "year"

    for (let year = today.getFullYear() - 6; year <= today.getFullYear(); year++) {
      const option = document.createElement("option")
      option.value = year
      option.textContent = year
      yearSelect.appendChild(option)
    }

    yearSelect.value = this.date.getFullYear()

    yearSelect.addEventListener("change", (event) => {
      this.date.setFullYear(parseInt(event.target.value))
      this.render()
    })

    this.yearTarget.appendChild(yearSelect)

    const headerRow = document.createElement("div")
    headerRow.classList.add("calendar-days")

    for (let i = 0; i < 7; i++) {
      const weekdayName = new Intl.DateTimeFormat("en-ZA", {
        weekday: "short",
      }).format(new Date(2021, 0, i + 3)).substring(0, 2)

      const weekdayHeader = document.createElement("span")
      weekdayHeader.classList.add("weekday-header")
      weekdayHeader.textContent = weekdayName
      headerRow.appendChild(weekdayHeader)
    }

    this.weekdayHeadersTarget.appendChild(headerRow)

    const startOfMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
    const endOfMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
    let currentDate = new Date(startOfMonth)
    currentDate.setDate(currentDate.getDate() - currentDate.getDay())

    while (currentDate < endOfMonth) {
      const week = document.createElement("div")
      week.classList.add("week")

      for (let i = 0; i < 7; i++) {
        const day = document.createElement("button")
        day.classList.add("day")
        day.dataset.action = "click->calendar-filter#daySelected"

        if (currentDate.getMonth() === this.date.getMonth()) {
          day.textContent = currentDate.getDate()
        } else {
          day.classList.add("muted")
          day.textContent = currentDate.getDate()
        }

        if (currentDate.getDay() === i) {
          week.appendChild(day)
          currentDate.setDate(currentDate.getDate() + 1)
        } else {
          const placeholderDay = document.createElement("div")
          placeholderDay.classList.add("day-placeholder")
          week.appendChild(placeholderDay)
        }
      }

      this.daysTarget.appendChild(week)
    }

    // Add event listeners to update input values when a day is selected.
    const days = this.daysTarget.querySelectorAll(".day")

    days.forEach(day => {
      day.addEventListener("click", () => {
        // Remove the selected class from all days.
        days.forEach(day => day.classList.remove(this.selectedClass))

        // Add the selected class to the clicked day.
        day.classList.add(this.selectedClass)
      })
    })
  }
}
