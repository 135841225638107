import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    expiresAt: Number
  }

  connect() {
    const expiresAtDate = new Date(this.expiresAtValue)
    const currentDate = new Date()
    this.timeout = window.setTimeout(this.reload.bind(this), (expiresAtDate - currentDate) + 1000)
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  reload() {
    window.location.reload()
  }
}