import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["formFrom", "formTo", "from", "to", "year", "month"]

  static values = {
    selectedFilter: String,
    selectedFromDate: String,
    selectedToDate: String
  }

  initialize() {
    this.today = new Date()
  }

  selectedFromDateValueChanged() {
    if (this.hasSelectedFromDateValue && this.selectedFromDateValue !== "") {
      const date = new Date(this.selectedFromDateValue)

      this.fromTarget.value = date.toLocaleDateString("en-ZA", { day: "numeric", month: "short", year: "numeric" })
      this.formFromTarget.value = date.toISOString()
    } else {
      this.fromTarget.value = ""
      this.formFromTarget.value = ""
    }
  }

  selectedToDateValueChanged() {
    if (this.hasSelectedToDateValue && this.selectedToDateValue !== "") {
      const date = new Date(this.selectedToDateValue)

      this.toTarget.value = date.toLocaleDateString("en-ZA", { day: "numeric", month: "short", year: "numeric" })
      this.formToTarget.value = date.toISOString()
    } else {
      this.toTarget.value = ""
      this.formToTarget.value = ""
    }
  }

  daySelected(event) {
    const day = event.target.textContent
    const year = this.yearTarget.value
    const month = this.monthTarget.dataset.monthIndex
    const date = new Date(Date.UTC(year, month, day))

    if (this.selectedFilterValue === "from") {
      this.selectedFromDateValue = date.toJSON()
    }

    if (this.selectedFilterValue === "to") {
      this.selectedToDateValue = date.toJSON()
    }
  }

  clearFilters() {
    this.selectedFromDateValue = undefined
    this.selectedToDateValue = undefined
  }

  fromFilter(event) {
    this.selectedFilterValue = "from"
  }

  toFilter(event) {
    this.selectedFilterValue = "to"
  }

  todayFilter(event) {
    this.selectedFromDateValue = this.today.toJSON()
    this.selectedToDateValue = this.today.toJSON()
  }

  last8DaysFilter() {
    const last8Days = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7)

    this.selectedFromDateValue = last8Days.toJSON()
    this.selectedToDateValue = this.today.toJSON()
  }

  lastMonthFilter() {
    const lastMonth = new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate() + 1)

    this.selectedFromDateValue = lastMonth.toJSON()
    this.selectedToDateValue = this.today.toJSON()
  }
}
