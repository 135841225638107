import ApplicationController from "./application_controller"
import { get } from "@rails/request.js"

export default class extends ApplicationController {
  static targets = ["destination"]
  static values = {
    url: String
  }

  async fetch(event) {
    if (this.element.hasAttribute("open")) {
      this.destinationTarget.innerHTML = ""
    } else {
      const response = await get(this.urlValue, { responseKind: "html" })
      if (response.ok) {
        const html = await response.html
        this.destinationTarget.innerHTML = html
      }
    }
  }
}